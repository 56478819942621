import dayjs from "dayjs"
//const sum = (arr) => arr.reduce((a, b) => a + b, 0)
const debug = false

function round2(gbp) {
	let t = Math.round(gbp * 100) / 100.0
	return t
}

class Order {
	constructor(o) {
		//		try {
		if (o) {
			// if (this.cancellation_time) {
			// 	console.log(`constructor ${this.order_id}`)
			// 	console.log(this.cancellation_time)
			// }
			Object.assign(this, o)
			this.updated_on = dayjs(this.updated_on)
			this.datetime = dayjs(this.datetime)
			this.printed_on = dayjs(this.printed_on)
			this.delivery_time = dayjs(this.delivery_time)
			this.cancellation_time = dayjs(this.cancellation_time)
			this.confirmation_time = dayjs(this.confirmation_time)
			this.printed_on = dayjs(this.printed_on)

			this.products = o.products ? o.products.map(x => new Product(x)) : []
			for (let p of this.products) {
				p.datetime = this.datetime
			}
			this.payments = o.payments ? o.payments : []
			for (var p of this.payments) {
				p.refund_time = dayjs(p.refund_time)
				p.datetime = dayjs(p.datetime)
				//p.confirmation_time = this.confirmation_time
			}
		} else {
			this.clear()
		}
	} // constructor

	get is_delivered() { return this.delivery_time.isValid(); }
	get is_cancelled() { return this.cancellation_time.isValid(); }
	get is_confirmed() { return this.confirmation_time.isValid(); }

	// get is_ready() {
	// 	let r = true
	// 	if (this.take_away === null) r = false
	// 	if (this.take_away === false && (this.table_number === null || this.table_number === '')) r = false
	// 	if (!this.products || this.products.length == 0) r = false
	// 	//console.log(r)
	// 	return r
	// } // is_ready

	get service_charge_yes_no() { return this.service_charge > 0 }
	set service_charge_yes_no(v) { this.service_charge = v ? 12.5 : 0 }

}

Order.prototype.clear = function () {
	this.products = []
	this.updated_on = dayjs()
	this.datetime = dayjs()
	this.delivery_time = dayjs(null)
	this.printed_on = dayjs(null)
	this.cancellation_time = dayjs(null)
	this.confirmation_time = dayjs(null)
	this.products = []
	this.payments = []
	this.order_id = null
	this.take_away = null
	this.service_charge = 0
} // clear

Order.prototype.waiting_minutes = function () {
	if (Array.isArray(this.products) == false || this.products.length == 0) return 0
	return Math.max.apply(null, this.products.map(p => p.waiting_minutes(this.confirmation_time)))
}

Order.prototype.total_price = function (include_discount = true) {
	if (!this.products) return 0
	let t = this.products.map(p => p.total_price(include_discount)).reduce((a, b) => a + b, 0)
	//let t = this.products.reduce((a, p) => a + p.total_price(include_discount), 0)
	return round2(t)
}

Order.prototype.service_charge_amount = function () {
	let t = (this.total_price() * this.service_charge) / 100
	return Math.round(t)
}

Order.prototype.paid_amount = function () {
	let t =
		this.payments
			.filter(p => !p.refund_time.isValid())
			.reduce((a, p) => a + p.amount, 0) || 0
	return t
}

Order.prototype.unpaid_amount = function () {
	let t = this.total_price(true) + this.service_charge_amount() - this.paid_amount()
	return t
} // unpaid_amount

Order.prototype.is_paid = function () {
	let t =
		this.total_price(true) + this.service_charge_amount() <=
		this.paid_amount()

	return t
} // is_paid

Order.prototype.is_late = function () {
	if (!this.products) return false
	if (this.cancellation_time.isValid()) return false
	return this.products.some(p => p.is_late())
} // is_paid

Order.prototype.add = function (product) {
	this.products.push(product)
}

Order.prototype.save = async function () {
	this.datetime = new Date()
	var o = await api2.put(`/101/orders/`, this)
	this.order_id = o.order_id

	for (var p of this.products) {
		p.order_id = this.order_id
		var r = await api2.put(`/101/orders/product`, p)
		p.id = r.id
	}

	return this.order_id
} // Order.Save

Order.prototype.insert_payment = async function (payment) {
	payment.payment_id = this.order_id
	var p = await api2.put(`/101/orders/payment/`, payment)
	payment.payment_id = p.payment_id
	this.payments.push(payment)
	return payment.payment_id
} // order.payment.insert

Order.prototype.deliver_product = async function (product) {
	var previous_delivery_time = this.delivery_time
	product.delivery_time = dayjs()
	var p = await api2.post(`/101/orders/product/deliver`, product)
	if (!this.products.some(x => !x.delivery_time.isValid()))
		this.delivery_time = dayjs()
	if (this.delivery_time != previous_delivery_time)
		var o = await api2.post(`/101/orders/deliver`, this)
	return p.id
} // order.payment.insert

Order.prototype.refund_payment = async function (payment) {
	payment.refund_time = new Date()
	var p = await api2.post(`/101/orders/payment/refund`, payment)
	return p.payment_id
} // order.payment.refund

class Product {
	constructor(o) {
		//console.log('Product')
		try {
			if (o) {
				Object.assign(this, o)
				this.delivery_time = dayjs(this.delivery_time)
				this.ordered_on = dayjs(this.ordered_on)
				//this.confirmation_time = dayjs(this.confirmation_time)
			} else {
				this.diet = '-'
				this.datetime = dayjs()
			}
			if (this.ingredients)
				this.ingredients = this.ingredients.map(x => new Ingredient(x))
			else
				this.ingredients = []
		} catch (e) { console.log(e) }
	}
} // Product

Product.prototype.diet = function () {
	let d = 'vegan'
	if (this.ingredients) {
		for (let i of this.ingredients.filter(x => x.quantity > 0)) {
			if (i.diet == 'meat') return 'meat'
			if (i.diet == 'vegetarian') d = 'vegetarian'
		}
	}
	return d
}

Product.prototype.waiting_minutes = function () {

	//console.log(this)
	//console.log(this.ordered_on)
	//if (!confirmation_time) return 0
	//if (!confirmation_time.isValid()) return 0
	if (!this.ordered_on.isValid()) return 0
	//if (!this.confirmation_time.isValid()) return 0
	var last = this.delivery_time.isValid() ? this.delivery_time : dayjs()
	return last.diff(this.ordered_on, 'minute')
}

Product.prototype.is_late = function () {
	if (this.product_id == 393) return false
	return this.waiting_minutes() > Math.max(this.delivery_minutes_max, 5)
}

Product.prototype.total_price = function (include_discount = true) {
	try {
		//		let cust = this.ingredients ? this.ingredients.filter(x => x.selected).reduce((a, b) => a + b.price , 0) : 0
		let cust = this.ingredients ?
			this.ingredients.filter(x => x.selected)
				.reduce((a, b) => a + b.inventory_unit_price * b.quantity, 0)
			: 0
		let t =
			Math.max(this.base_price + cust, this.minimum_price) *
			(include_discount ? (100 - this.discount) / 100 : 1)
		//console.log(`ingredient : ${this.name}`)
		//console.log(this.base_price)
		//console.log(cust)
		//console.log(t)
		return Math.round(t)
	} catch (e) {
		console.log(e)
		return null
	}
} // total_price

Product.prototype.contains = function (s) {
	var sl = s.toLowerCase()
	this.ranking = 0
	if (this.name && this.name.toLowerCase().indexOf(sl) >= 0) this.ranking += 4
	if (this.description && this.description.toLowerCase().indexOf(sl) >= 0)
		this.ranking += 3
	if (
		this.description_short &&
		this.description_short.toLowerCase().indexOf(sl) >= 0
	)
		this.ranking += 2
	if (
		this.ingredients &&
		this.ingredients.filter(i => i.name ? i.name.toLowerCase().indexOf(sl) >= 0 : false).length >
		0
	)
		if (
			this.ingredients2 &&
			this.ingredients2.filter(i => i.name.toLowerCase().indexOf(sl) >= 0).length >
			0
		)
			this.ranking += 1
	return this.ranking > 0
} // contains


class Ingredient {
	constructor(o) {
		//console.log('Ingredient')
		try {
			if (o) { Object.assign(this, o) } else { }
		} catch (e) { console.log(e) }
	}
	// get selected_option() {
	// 	//console.log(this.options)
	// 	if(!this.options || !this.options instanceof Array) return null
	// 	if (this.options && this.options instanceof Array && this.options.length > 0) {
	// 		return this.options.find(x => x.selected)
	// 	}
	// 	return null;
	// }
	get name_with_option() {
		//console.log(this.selected_option)
		var s = this.name_pos
		try {
			let so = []
			if (this.options || this.options instanceof Array) {
				for (let o of this.options) {
					let x = o.find(x => x.selected)
					if (x) so.push(x)
				}
			}
			if (so.length > 0)
				s += ` : ${so.map(x => x.name).join(', ')}`
		} catch (e) {
			console.log(e);
		}
		finally { return s }
	}
} // Ingredient

Ingredient.prototype.name_lower = function () { this.name ? this.name.toLowerCase() : '' } // name_lower

const Db = {
	Sales: {
		async Get(type, start_date) {
			try {
				var sales = await api2.get('/100/sales/')
				console.log(`sales loaded ${sales.length}`)
				return sales
			} catch (e) { console.log(e) }
		}, // Products.Get
	}, // Sales
	Products: {
		async Get() {
			try {
				var products = await api2.get('/101/products')
				console.log(`products loaded ${products.length}`)
				return products.map(x => new Product(x))
			} catch (e) { console.log(e) }
		}, // Products.Get
	},

	Orders: {
		async Get(dt, filter, track_id) {
			try {
				filter = filter || 'updated_on'
				const url = new URL(`/101/orders`, window.location.origin);

				console.log(`${filter}: ${dt}`)
				//var d = dt ? `?${filter}=${dt.getTime() / 1000}` : ''
				if (dt) url.searchParams.append(filter, dt.getTime() / 1000);
				url.searchParams.append("track_id", track_id);

				console.log(`GET: ${url.href}`)
				var o = await api2.get(url.href)
				console.log(o)
				return await o.map(x => new Order(x))
			} catch (e) {
				console.log(e)
			}
		}, // Orders.Get 
		async Insert(order) {
			return await api2.put(`/101/orders/`, order)
		}, // Orders.Insert
	}, // Orders

	Payments: {
		async Insert(payment) {
			return await api2.put(`/101/orders/payment/`, payment)
		}, // Payments.Insert
		async Refund(payment) {
			return await api2.post(`/101/orders/payment/refund`, payment)
		}, // Payments.Refund
	}, // Payments

	Ingredients: {
		async Get() {
			let o = await api2.get(`/101/ingredients/`)
			return o.map(x => new Ingredient(x))
		}, // Orders.Get */
	}, // Ingredients

	Categories: {
		async Get() {
			var items = await api2.get('/101/products/categories')
			console.log(`categories loaded ${items.length}`)
			//console.log(items)
			return items
		}, // Products.Get
	},
}

const api2 = {
	// handleErrors(response) {
	// 	console.log(JSON.stringify(response))
	// 	if (!response.ok) {
	// 		throw Error(response.statusText)
	// 	}
	// 	return response
	// },
	async get(url, params) {
		if (params)
			url = url + '?' + new URLSearchParams(params)

		return await this.method('get', url)
	},
	async post(url, d) {
		return await this.method('post', url, d)
	},
	async put(url, d) {
		return await this.method('put', url, d)
	},
	async delete(url, d) {
		return await this.method('delete', url, d)
	},
	async method(verb, url, data) {
		if (debug) console.log(`api2:${verb}->${url}`)
		const response = await fetch(url, {
			credentials: 'include',
			method: verb,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				mode: 'same-origin',
				redirect: 'follow',
				credentials: 'include',
			},
			body: JSON.stringify(data),
		})
		if (!response.ok) throw Error(response.statusText)

		const text = await response.text()
		return text.length ? JSON.parse(text) : {}
	},
}

export { Db, Order, Product, round2, api2, Ingredient }
