<template>
	<div class="overlay" :class="{ isOpen: is_open }">
		<a href="javascript:void(0)" class="closebtn" v-on:click="close()"
			>&times;</a
		>
		<div class="overlay-content is-flex is-flex-direction-column">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SideBar',
		props: { open: Boolean },
		watch: {
			open: function (o) {
				//console.log(o);
				this.is_open = o
			},
		}, // watch
		data: function () {
			return { is_open: this.value }
		},
		methods: {
			close() {
				this.is_open = false
				this.$emit('update:open', this.is_open)
			},
		}, // methods
	}
</script>

<style lang="less">
	/* The Overlay (background) */
	.overlay {
		/* Height & width depends on how you want to reveal the overlay (see JS below) */
		height: 100%;
		width: 0;
		position: fixed;
		/* Stay in place */
		z-index: 2000;
		/* Sit on top */
		left: auto;
		top: 0;
		background-color: rgb(0, 0, 0);
		/* Black fallback color */
		background-color: rgba(0, 0, 0, 0.9);
		/* Black w/opacity */
		overflow-x: hidden;
		/* Disable horizontal scroll */
		transition: 0.5s;

		/* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

		&.isOpen {
			width: 100%;
			max-width: 480px;
		}
		/* Position the close button (top right corner) */
		.closebtn {
			position: absolute;
			right: 0.5em;
			font-size: 3em;
		}
	}

	/* Position the content inside the overlay */
	.overlay-content {
		position: relative;
		top: 5%;
		/* 25% from the top */
		width: 100%;
		/* 100% width */
		text-align: left;
		/* Centered text/links */
		margin-top: 30px;
		padding-left: 2em;
		padding-right: 2em;
		/* 30px top margin to avoid conflict with the close button on smaller screens */

		hr {
			margin-top: 0;
			margin-bottom: 1.2em;
		}

		.checkbox {
			font-size: 1.2em !important;
			margin-bottom: 1.2em !important;
			color: white;

			label:first-letter {
				text-transform: capitalize;
			}

			input[type='checkbox'] {
				transform: scale(2);
				margin-right: 1.5em;
			}
		}
		/* The navigation links inside the overlay */
		a {
			padding: 8px;
			text-decoration: none;
			font-size: 2em;
			color: #818181;
			display: block;
			/* Display block instead of inline */
			transition: 0.3s;
			/* Transition effects on hover (color) */

			/* When you mouse over the navigation links, change their color */
			&:hover,
			&:focus {
				color: #f1f1f1;
			}
		}
	}

	/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
	@media screen and (max-height: 450px) {
		.overlay-content {
			a {
				font-size: 20px;
			}

			.closebtn {
				font-size: 40px;
				top: 15px;
				right: 35px;
			}
		}
	}
</style>
