<template>
	<div class="header" style="justify-content: space-evenly">
		<img src="/va_pensiero_logo_pink.png" />


		<a href="/login" class="" v-if="!$auth.user">
			Login
		</a>
		<div class="" v-else v-on:click="logout()">
			<div>Logout</div>
			<div :class="{ 'has-text-warning': $auth.is_staff }">{{ $auth.user ? '(' + $auth.user.name + ')' : '' }}</div>
		</div>
		<div class="" v-if="$auth.employee" v-on:click="sidebar = true">
			<i class="fas fa-bars"></i>
		</div>
	</div>

	<SideBar v-model:open="sidebar">
		<div>
			You are {{ $socket.is_connected ? '' : ' not ' }} connected
			<span class="icon" v-if="$socket.is_connected">
				<i class="fa fa-wifi fa-lg" aria-hidden="true"></i>
			</span>
		</div>
		<div>Reports</div>
		<a href="#" @click="sidebar_go('/reports/payments')">Payments</a>
		<a href="#" @click="sidebar_go('/reports/coffee')">Coffee</a>
		<a href="#" @click="sidebar_go('/reports/departments')">Departments</a>
		<a href="#" @click="sidebar_go('/reports/delivery')">Delivery</a>
		<a href="#" @click="sidebar_go('/reports/tips')">Tips</a>
		<a href="#" v-if="$auth.has_permission('pos.reports.orders')" @click="sidebar_go('/reports/orders')">Orders</a>
		<a href="#" v-if="$auth.has_permission('pos.reports.sales')" @click="sidebar_go('/reports/sales')">
			Sales
		</a>
		<a href="#" v-if="$auth.has_permission('pos.reports.sales')" @click="sidebar_go('/reports/sales2')">
			Sales2
		</a>
		<a href="#" v-if="$auth.has_permission('pos.reports.sales')" @click="sidebar_go('/reports/sales-monthly')">
			Sales Monthly
		</a>
		<hr />
		<a href="#" v-if="$auth.has_permission('pos.products.edit')" @click="sidebar_go('/edit')">
			Edit
		</a>
		<hr />
		<a href="#" v-if="$auth.has_permission('pos.admin')" @click="broadcast('__REFRESH__')">refresh all</a>
		<a href="#" v-if="$auth.has_permission('pos.admin')" @click="broadcast('__LOGOUT__')">logout all</a>
		<hr />
	</SideBar>

	<!-- https://markus.oberlehner.net/blog/vue-router-page-transitions/ -->

	<div class="body">
		<!-- <h1 class="title has-background-link-dark mt-4" v-on:click="$router.push(`/order/${$current.order.order_id}`)"
			v-if="$current.order">
			Order: {{ $current.order.order_id }}
		</h1> -->

		<!-- <router-view v-slot="{ Component }" v-if="$products.items"> -->
		<!-- <router-view v-slot="{ Component }" v-if="$page.is_ready">
			<transition name="fade" mode="out-in">
				<div :key="$router.currentRoute.path">
					<component :is="Component"></component>
				</div>
			</transition>
		</router-view> -->

		<router-view v-if="$page.is_ready" />


		<!-- <transition name="fade" mode="out-in">
			<router-view
				v-if="$products.items"
				:option="options.find((a) => a.selected).name"
			/>
		</transition> -->
	</div>

	<nav class="mobile-bottom-nav">

		<div class="mobile-bottom-nav__item" :class="{ 'has-text-warning': tab_selected == '' }">
			<div class="mobile-bottom-nav__item-content" v-on:click="add_order()">
				<button class="button is-warning">New Order</button>
			</div>
		</div>

		<div class="mobile-bottom-nav__item" v-if="show_checkout">
			<div class="mobile-bottom-nav__item-content">
				<button class="button is-danger is-large" v-on:click="$router.push(`/order/${order.order_id}`)">
					<span title="Badge top right" class="badge is-danger">
						{{ order.products.length }}
					</span>
					Checkout
				</button>
			</div>
		</div>

		<div class="mobile-bottom-nav__item" :class="{ 'has-text-warning': tab_selected == 'orders' }">
			<div class="mobile-bottom-nav__item-content" v-on:click="go('orders')">
				<button class="button is-link">
					<span title="Badge top right" class="badge is-danger" v-show="open_orders.length > 0">
						{{ open_orders.length }}
					</span>
					Orders
				</button>
			</div>
		</div>
	</nav>

	<!-- <div class="loader-wrapper is-active" v-if="$page.is_loading">
		<div class="loader is-loading" style="height: 4em; width: 4em"></div>
	</div> -->


	<!-- <SpinnerJump v-if="$page.is_loading"></SpinnerJump> -->
	<div class="result-box load bounce" v-if="$page.is_loading">
		<Spinner></Spinner>
	</div>
	<div class="result-box success bounce" v-if="$page.is_success">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
			<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1"
				cy="65.1" r="62.1" />
			<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round"
				stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
		</svg>
	</div>
	<div class="result-box failure bounce" v-if="$page.is_failure">
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
			<circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1"
				cy="65.1" r="62.1" />
			<line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
				stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
			<line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
				stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
		</svg>
	</div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import Spinner from '@/components/SpinnerDna.vue'
import { Order } from './helpers'
//	import SpinnerJump from '@/components/SpinnerJump.vue'
//import dayjs from "dayjs"

export default {
	props: {}, // props
	components: { SideBar, Spinner },
	data() {
		return {
			tab_selected: '',
			sidebar: false,
		}
	},
	methods: {
		logout() {
			localStorage.removeItem('access_token');
			localStorage.removeItem('id_token');
			localStorage.removeItem('expires_at');
			window.location.href = "/logout";

		},
		async broadcast(s) {
			console.log(`BROADCAST ${s}`)
			try {
				let r = await this.$api2.put(`/101/broadcast`, { command: s })
				this.$page.success()
			} catch (error) {
				console.log(error)
				this.$page.failure()
			}
		},

		async add_order() {
			console.log(`CREATE order`)
			let order = new Order()
			//this.error = null
			try {
				//order.datetime = new this.$dayjs()
				order.created_by = this.$auth.is_staff ? this.$auth.employee.employee_id : null
				//order.cancellation_time = this.$dayjs(null)
				order.track_id = this.$auth.track_id
				order.from_customer = this.$auth.is_customer
				if (this.$auth.customer_area) {
					order.customer_area = this.$auth.customer_area
					order.take_away = this.$auth.customer_area == 'take away'
				}
				console.log(order)
				this.$page.load(true)
				this.$current.order = order
				//this.$orders.items.push(order)

				console.log(`created order ${order.order_id}`)

				this.$router.push(`/category`)
				this.$page.load(false)
				this.$page.success()
			} catch (error) {
				console.log(error)
				this.$page.load(false)
				this.$page.failure()
			}
			finally {
				//this.saving = false
			}
		},
		go(tab) {
			console.log(tab)
			let url = `/${tab}`
			this.tab_selected = tab
			this.$router.push(url)
		}, // go
		sidebar_go(url) {
			console.log(url)
			this.sidebar = false
			var r = this.$router
			setTimeout(() => {
				r.push(url)
			}, 300)
		}, // sidebar_go
	}, // methods
	computed: {

		open_orders: function () {
			return this.$orders.items.filter(
				x =>
					x.order_id &&
					!x.cancellation_time.isValid() &&
					(!x.delivery_time.isValid() || x.is_paid() == false),
			)
		}, // open_orders
		order: function () { return this.$current.order }, //order
		show_checkout() { return this.$current.order && this.$current.order.products.length > 0 }, // show_checkout
		// show_bottom_navbar() {
		// 	return this.$auth.is_staff || this.$auth.customer_area != null
		// }, // show_bottom_navbar
	}, // computed
	mounted: async function () {
		console.log(this.$auth)
		//if (this.$auth.employee) window.location.href = '/logout'
	},
}
</script>

<style lang="less">
@navbar_height: 3em;
@footer_height: 3em;
@max_width: 600px;

.icon {
	&.is-action {
		cursor: pointer;
		pointer-events: initial !important;
	}
}

.coverable {
	position: relative;
	padding: 2em;

	.cover {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #000;
		opacity: 0.5;
		z-index: 10;
	}
}

html {
	//background-color: #262626;
	//background-color: #333;

	font-family: 'Roboto', sans-serif;
}

p,
h1,
h2 {
	//color:white;
	//color: #333;
	//font-family: 'Roboto', sans-serif;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	//color: #2c3e50;
}

.header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #375a7f;
	margin: 0;
	padding: 1em 0;

	img {
		width: 30%;
	}
}

body {
	max-width: @max_width;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 70px;
	//padding-bottom: 54px;
	//background-color:#a0a0a0;
}

.full-height {
	height: calc(100vh - @navbar_height - @footer_height - 1px);
	max-height: calc(100vh - @navbar_height - @footer_height - 1px);

	h1 {
		margin: 0;
	}

	padding-top: 1em;
	padding-bottom: 1em;
}

// .FooterBar {
// 	max-width: @max_width;
// 	margin-left: auto;
// 	margin-right: auto;

// 	overflow: hidden;
// 	background-color: #375a7f;
// 	position: fixed;
// 	margin-top: 0;
// 	margin-bottom: 0;
// 	padding: 0;
// 	bottom: 0;
// 	left: auto;
// 	width: 100%;
// 	height: @footer_height;
// 	display: flex;
// 	flex-direction: row;
// 	flex-wrap: no-wrap;
// 	justify-content: space-evenly;

// 	div {
// 		display: block;
// 		color: #e0c9bc;
// 		text-align: center;
// 		padding: 0.2rem 0;
// 		margin: 0;
// 		padding: 1em 0;
// 		text-decoration: none;
// 		font-size: 1rem;
// 		flex: auto 1 1;

// 		&:hover {
// 			background: #f1f1f1;
// 			color: black;
// 		}

// 		&.active {
// 			background-color: #4caf50;
// 			color: white;
// 		}
// 	}
// }

// @media all and (min-width: 360px) {
// 	.FooterBar {
// 		a {
// 			font-size: 0.7rem;
// 		}
// 	}
// }

// @media all and (min-width: 480px) {
// 	.FooterBar {
// 		a {
// 			font-size: 1rem;
// 		}
// 	}
// 	body {
// 		//margin-bottom: 4rem;
// 	}
// }

// .grid-wrapper {
// 	margin-top: 0;
// 	//margin-left: 0;
// 	//margin-right: 0;
// 	//padding-bottom: @footer_height;
// 	padding: 0;

// 	height: calc(100vh - @footer_height - @navbar_height);
// 	//height: max(100px, calc(100vh - @footer_height - @navbar_height));
// 	//margin:0;
// 	display: grid;
// 	grid-gap: 1px; //
// 	grid-template-rows: repeat(3, 33%);
// 	grid-template-columns: repeat(3, 33%);
// 	//grid-auto-columns: 1fr;
// 	//grid-auto-rows: 1fr;
// 	background-color: #333;
// }

// .column {
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-between;
// 	align-items: stretch;
// }

// .row {
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: space-between;
// 	align-items: stretch;
// }

/*
          router transitions
*/

.fade-enter-active,
.fade-leave-active {
	transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.scale-enter-active,
.scale-leave-active {
	transition: all 0.9s ease;
}

.scale-enter-from,
.scale-leave-to {
	opacity: 0;
	transform: scale(0.9);
}

.wrapper {
	width: 100%;
	min-height: 100vh;
}

// .slide-enter-active,
// .slide-leave-active {
// 	transition: all 0.75s ease-out;
// }

// .slide-enter-to {
// 	position: absolute;
// 	right: 0;
// }

// .slide-enter-from {
// 	position: absolute;
// 	right: -100%;
// }

// .slide-leave-to {
// 	position: absolute;
// 	left: -100%;
// }

// .slide-leave-from {
// 	position: absolute;
// 	left: 0;
// }

// .p-badge {
// 	height: 1rem;
// 	line-height: 1rem;
// 	min-width: 1rem;
// 	font-size: 0.5rem;
// }

label {
	// background-color:black;
	//font-family: 'Source Sans Pro', sans-serif;

	position: relative;
	cursor: pointer;
	font-size: 1em;
	font-weight: 600;
	padding: 0 0.25em 0;
	user-select: none;

	&::before {
		top: 0;
		position: absolute;
		content: attr(data-content);
		//color: lightslategray;
		//     opacity:50%;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

		text-decoration: line-through;
		text-decoration-thickness: 1px;
		text-decoration-color: slategrey;
		transition: clip-path 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}

.modal {
	bottom: @bottom-nav-height !important;
}

@bottom-nav-height: 70px;

.body {
	padding-bottom: @bottom-nav-height;
}

.mobile-bottom-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	will-change: transform;
	transform: translateZ(0);

	display: flex;
	height: @bottom-nav-height;
	opacity: 1;

	box-shadow: 0 -2px 5px -2px #333;
	background-color: black;

	&__item {
		flex-grow: 1;
		text-align: center;
		//font-size: 12px;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__item--active {
		//dev
		//color: red;
	}

	&__item-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

/*
	spinner
*/
.loader-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #fff;
	opacity: 0;
	z-index: -1;
	transition: opacity 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	background-color: black;
	opacity: 0.9;

	.loader {
		height: 30vw;
		width: 30vw;
	}

	&.is-active {
		opacity: 1;
		z-index: 10000;
	}
}

.floating-button {
	position: fixed;
	z-index: 10;

	&.left {
		bottom: 10px;
		left: 10px;
	}
}



.checkout {
	bottom: 80px;
	left: 50%;
	transform: translateX(-50%);
}

.clickable {
	pointer-events: all;
	cursor: pointer;
}

// .icon {
// 	&.is-action {
// 		cursor: pointer;
// 		pointer-events: initial !important;
// 	}
// }

//  CHECKMARK
// https://csshint.com/animated-check-mark-cross-icon/
.result-box {
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	padding: 3em;
	opacity: 0.9;
	border-radius: 25px;

	&.success {
		background-color: green;
	}

	&.failure {
		background-color: red;
	}

	&.load {
		background-color: black;
	}

	svg {
		width: 100px;
		display: block;
		//margin: 40px auto 0;
	}

	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 0;

		&.circle {
			-webkit-animation: dash 0.9s ease-in-out;
			animation: dash 0.9s ease-in-out;
		}

		&.line {
			stroke-dashoffset: 1000;
			-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
			animation: dash 0.9s 0.35s ease-in-out forwards;
		}

		&.check {
			stroke-dashoffset: -100;
			-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
			animation: dash-check 0.9s 0.35s ease-in-out forwards;
		}
	}

	.bounce {
		animation: bounce 1s 0.5s;
		transform: scale(0.85);
	}

	@keyframes bounce {
		0% {
			transform: scale(1.1);
			opacity: 1;
		}

		50% {
			transform: scale(1.6);
			opacity: 0.7;
		}

		60% {
			transform: scale(0.6);
			opacity: 1;
		}

		80% {
			transform: scale(0.95);
		}

		100% {
			transform: scale(0.85);
		}
	}

	@-webkit-keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}

		100% {
			stroke-dashoffset: 0;
		}
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: 1000;
		}

		100% {
			stroke-dashoffset: 0;
		}
	}

	@-webkit-keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}

		100% {
			stroke-dashoffset: 900;
		}
	}

	@keyframes dash-check {
		0% {
			stroke-dashoffset: -100;
		}

		100% {
			stroke-dashoffset: 900;
		}
	}
}

//	https://loading.io/css/

.lds-heart {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	transform: rotate(45deg);
	transform-origin: 40px 40px;
}

.lds-heart div {
	top: 32px;
	left: 32px;
	position: absolute;
	width: 32px;
	height: 32px;
	background: red;
	animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lds-heart div:after,
.lds-heart div:before {
	content: ' ';
	position: absolute;
	display: block;
	width: 32px;
	height: 32px;
	background: red;
}

.lds-heart div:before {
	left: -24px;
	border-radius: 50% 0 0 50%;
}

.lds-heart div:after {
	top: -24px;
	border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
	0% {
		transform: scale(0.95);
	}

	5% {
		transform: scale(1.1);
	}

	39% {
		transform: scale(0.85);
	}

	45% {
		transform: scale(1);
	}

	60% {
		transform: scale(0.95);
	}

	100% {
		transform: scale(0.9);
	}
}

// ----------------------------------------------------------------------
// -------------  CHECKBOXES  -------------------------------------------
// ----------------------------------------------------------------------

@black: #363839;
@lightgray: #9c9e9f;
@gray: #bdc1c6;
@white: #fff;
@green: #06842c;

.checkbox.large {
	font-size: 1.5em;
	line-height: 2em;

	//	input[type='checkbox'] {
	// transform: scale(1.5);
	// margin-right: 0.4em;
	//}
	input[type='checkbox'] {
		position: relative;
		width: 2em;
		height: 2em;
		color: @black;
		border: 1px solid @gray;
		border-radius: 4px;
		appearance: none;
		outline: 0;
		cursor: pointer;
		transition: background 225ms cubic-bezier(0.1, 0.1, 0.25, 1);

		&::before {
			position: absolute;
			content: '';
			display: block;
			top: 2px;
			left: 7px;
			width: 8px;
			height: 14px;
			border-style: solid;
			border-color: @white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			opacity: 0;
		}


		&:checked {
			color: @white;
			border-color: @green;
			background: @green;

			&::before {
				opacity: 1;
			}

			~label::before {
				clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
			}
		}

		&:disabled {
			filter: brightness(25%); //background: @gray;
		}

	}
}

.radio.large {
	font-size: 2em;
	line-height: 1.4em;

	input[type='radio'] {
		/* remove standard background appearance */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		/* create custom radiobutton appearance */
		display: inline-block;
		width: 2em;
		height: 2em;
		padding: 4px;
		/* background-color only for content */
		background-clip: content-box;
		border: 2px solid #bbbbbb;
		background-color: #e7e6e7;
		border-radius: 50%;
	}

	/* appearance for checked radiobutton */
	input[type='radio']:checked {
		background-color: #06842c;
		//		background-color: #93e026;
	}
}
</style>
