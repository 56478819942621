import { reactive } from 'vue'
import { Db, Order } from './helpers'
import dayjs from 'dayjs'
import { uuidv7 } from "uuidv7";

var config = reactive({
	delivery: {
		kitchen: null,
		bar: null,
		prepared: null,
	},
	average: array => {
		if (array.length == 0) return 0
		return array.reduce((partialSum, a) => partialSum + a, 0) / array.length
	},
	async init() {
		try {
			// var orders = await Db.Orders.Get(
			// 	dayjs().subtract(7, 'day').toDate(),
			// 	'datetime', auth.track_id
			// )
			var orders = await Db.Orders.Get(
				dayjs().startOf('day').toDate(),
				'datetime', auth.track_id
			)
			var products = []
			for (var o of orders) {
				for (var p of o.products) {
					//p.datetime = dayjs(o.datetime)
					//p.delivery_time = dayjs(o.delivery_time)
					//p.waiting_minutes = p.delivery_time.diff(p.datetime, 'minute', true)
					p.date = p.datetime.format('YYYY-MM-DD')
					if (!Number.isNaN(p.waiting_minutes)) products.push(p)
				}
			}
			var kitchen = products
				.filter(x => x.preparation == 'kitchen')
				.map(x => x.waiting_minutes)

			var bar = products
				.filter(x => x.preparation == 'bar')
				.map(x => x.waiting_minutes)

			var prepared = products
				.filter(x => x.preparation == 'preparation')
				.map(x => x.waiting_minutes)

			this.delivery.kitchen = this.average(kitchen)
			this.delivery.bar = this.average(bar)
			this.delivery.prepared = this.average(prepared)
		} catch (e) {
			console.log(e)
		}
	}
})

var products = reactive({
	items: null,
	extras: null,
	categories: null,
	async init(page) {
		try {
			console.log('products.init')
			page.load(true)
			//this.extras = await Db.Extras.Get()
			this.categories = await Db.Categories.Get()
			//console.log('categories loaded')
			this.items = await Db.Products.Get()
			console.log(this.items)
			if (this.items)
				for (var x of this.items) {
					x.categories = this.categories.filter(y =>
						x.category_ids.includes(y.category_id),
					)
				}
			// console.log(this.items)
		} catch (e) {
			console.log(e)
			page.failure()
		} finally {
			console.log('products done')
			page.load(false)
		}
	}, // init
})


function delay(millisec) {
	return new Promise(resolve => {
		setTimeout(() => { resolve('') }, millisec);
	})
}

var auth = reactive({
	role: 'guest',
	track_id: null,
	customer_area: null,
	employees: null,
	employee: null,
	user: null,
	role: null,
	token: null,
	is_loading: true,
	get_user: function () {
		//return this._user
		//console.log('user')
		return new Promise(async (resolve, reject) => {
			try {
				//console.log('user.promise')
				while (this.is_loading) {
					//console.log('Not yet, waiting more');
					await delay(100);
				}
				//console.log(this.user)
				resolve(this.user)
			} catch (e) { reject(e) }
		});
	},
	init: async function () {
		try {
			page.load(true)
			this.track_id = localStorage.getItem('trkVPL') || uuidv7()
			console.log(`auth.init, track_id:${this.track_id}`)
			localStorage.setItem('trkVPL', this.track_id)

			var r = await fetch('/100/employees/current')
			//console.log(r)
			if (r.status != 200) {
				console.log('is_loading')
				this.is_loading = false
				return false
			}
			var e = await r.json()
			page.load(false)
			//console.log(e)
			if (!e || e.status == 'not authenticated') {
				console.log('is_loading')
				this.is_loading = false
				return false
			}
			this.user = e.user
			this.is_loading = false
			console.log('is_loading')

			if (e.employee) {
				this.employee = e.employee
				this.employees = e.employees
				this.role = this.employee.role
				this.token = e.token
				//return true
			}

			//console.log(this.employee.permissions)
			return true
		} catch (e) {
			console.log(e)
			this.is_loading = false
			return false
		}
	},
	//set_role: function(r){ this.role = r },
	get is_customer() {
		return this.employee == null || this.user == null || this.role == null || this.role == '';
	},
	get is_staff() {
		if (this.role == null || this.role == '') return false;
		return this.role == 'staff' || this.role == 'admin'
	},
	get is_admin() {
		if (this.role == null) return false;
		return this.role == 'admin'
	},
	has_permission: function (p) {
		//console.log(this.employee.permissions)
		return this.employee ? this.employee.permissions.includes(p) : false
	},
})

var orders = reactive({
	items: [], // array of orders
	last_update: new Date(new Date().setUTCHours(0, 0, 0, 0)),
	paid: function () {
		return this.items.filter(x => x.is_paid() == true) || []
	},
	unpaid: function () {
		return this.items.filter(x => x.is_paid() == false) || []
	},
	update: async function (is_staff) {
		try {
			console.log(`updating orders since ${this.last_update}`)
			//this.last_update.setDate(this.last_update.getDate() - 1);
			console.log('orders.init, staff:' + is_staff)

			var d = await Db.Orders.Get(this.last_update, 'updated_on', auth.track_id)
			if (d.length == 0) return
			console.log(`loaded ${d.length} orders`)

			var d_id = d.map(x => x.order_id)
			this.items = this.items
				.filter(x => d_id.includes(x.order_id) == false)
				.concat(d)
				.sort((a, b) => b.datetime - a.datetime)
			this.last_update = d
				.map(x => x.updated_on)
				.reduce(function (a, b) {
					return a > b ? a : b
				}).toDate()
		} catch (e) { console.log(e) }
	}, // update
	replace_order: function (order) {
		if (!order) return
		console.log(`replacing order ${order.order_id}`)
		try {
			console.log(`loaded order ${order.order_id}`)
			this.items = this.items.filter(x => x.order_id != order.order_id)
			this.items.push(new Order(order))
			this.items.sort((a, b) => b.datetime.isBefore(a.datetime))
		} catch (e) { console.log(e) }
	}, // replace_order
	reload_order: async function (order_id) {
		if (!order_id) return
		try {
			this.last_update.setDate(this.last_update.getDate() - 1);

			var d = await Db.Orders.get_single(order_id)
			if (d.length == 0) return
			this.replace_order(d)
		} catch (e) { console.log(e) }
	}, // update_order
})

var page = reactive({
	name: '',
	parent: null,
	_loading: 0,
	is_success: false,
	is_failure: false,
	is_ready:false,
	get is_loading() {
		return this._loading > 0
	},

	success() {
		//console.log('success')
		this.is_success = true
		setTimeout(() => {
			this.is_success = false
		}, 1200)
	},
	failure() {
		this.is_failure = true
		setTimeout(() => {
			this.is_failure = false
		}, 2400)
	},
	load(v) {
		this._loading += v ? 1 : -1
	}, // load
	//get is_ready() { return this._loading == 0 },
})

// var track = reactive({
// 	tracker_id: null,
// 	get_tracker: async function () {
// 		if (this.tracker_id == null) {
// 			var r1 = await fetch('/100/login/get_tracker')
// 			var d1 = await r1.json()
// 			console.log(d1)
// 			this.tracker_id = d1.tracker_id
// 		}
// 		return this.tracker_id
// 	},
// })

// function uuidv4() {
// 	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
// 		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
// 	);
// }


export { orders, products, page, auth, config }
