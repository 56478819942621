<template>
    <div class="dna">
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
        <div class="ele"></div>
    </div>
    <!-- <div class="background"></div> -->
</template>

<!-- https://codepen.io/ghost028/pen/KgMerN -->

<script>
export default {
    name: 'Spinner',
    props: {}, // props
    components: {},
    data() { return {} },
}
</script>

<style lang="less" scoped>
// :root {
//   --bg-color: radial-gradient(circle at center, #fff, #ccc);
// }

.background {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    //background-color: black;
//    z-index: 1;
}

// body {
//   background: var(--bg-color);
//   background-size: cover;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// }

.dna {
  width: 360px;
  height: 70px;
  perspective: 400px;
  transform-style: preserve-3d;
}

.ele {
  width: 1px;
  height: 70px;
  float: left;
  margin: 0 8px;
  border-left: 1px #B0B0B0 dashed;
  position: relative;
  transform: rotateX(-360deg);
  animation: run 2s linear infinite;
}
.ele:before, .ele:after {
  content: "";
  width: 10px;
  height: 10px;
  background: blue;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ele:before {
  top: -2px;
  background: red;
}
.ele:after {
  bottom: -2px;
}
.ele:nth-of-type(1) {
  animation-delay: -0.15s;
}
.ele:nth-of-type(2) {
  animation-delay: -0.3s;
}
.ele:nth-of-type(3) {
  animation-delay: -0.45s;
}
.ele:nth-of-type(4) {
  animation-delay: -0.6s;
}
.ele:nth-of-type(5) {
  animation-delay: -0.75s;
}
.ele:nth-of-type(6) {
  animation-delay: -0.9s;
}
.ele:nth-of-type(7) {
  animation-delay: -1.05s;
}
.ele:nth-of-type(8) {
  animation-delay: -1.2s;
}
.ele:nth-of-type(9) {
  animation-delay: -1.35s;
}
.ele:nth-of-type(10) {
  animation-delay: -1.5s;
}
.ele:nth-of-type(11) {
  animation-delay: -1.65s;
}
.ele:nth-of-type(12) {
  animation-delay: -1.8s;
}
.ele:nth-of-type(13) {
  animation-delay: -1.95s;
}
.ele:nth-of-type(14) {
  animation-delay: -2.1s;
}
.ele:nth-of-type(15) {
  animation-delay: -2.25s;
}
.ele:nth-of-type(16) {
  animation-delay: -2.4s;
}
.ele:nth-of-type(17) {
  animation-delay: -2.55s;
}
.ele:nth-of-type(18) {
  animation-delay: -2.7s;
}
.ele:nth-of-type(19) {
  animation-delay: -2.85s;
}
.ele:nth-of-type(20) {
  animation-delay: -3s;
}

@keyframes run {
  to {
    transform: none;
  }
}</style>
