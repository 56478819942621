import { createRouter, createWebHistory } from 'vue-router'
//import {useState} from '../store/user.js'

//var user = useState()

// import {provide, inject} from 'vue'
// var u = inject('user')
// console.log(`ROUTER: u: ${JSON.stringify(u)}`)

//import { authGuard } from "@auth0/auth0-vue";

const routes = [
	{
		path: '/',
		redirect: '/orders/',
	},
	{
		path: '/table/:table_code?',
		name: 'Table',
		props: true,
		component: () => {
			return import(/* webpackChunkName: "main" */ './views/Table.vue')
		},
	},
	{
		path: '/category/:id?',
		name: 'category',
		beforeEnter: authGuard,
		props: true,
		component: () => {
			return import(/* webpackChunkName: "main" */ './views/Category.vue')
		},
	},
	{
		path: '/orders',
		name: 'orders',
		beforeEnter: authGuard,
		component: () => {
			return import(/* webpackChunkName: "staff" */ './views/Orders.vue')
		},
	},
	{
		path: '/order/:order_id',
		name: 'order',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(/* webpackChunkName: "staff" */ './views/Order.vue')
		},
	},
	// {
	// 	path: '/search/',
	// 	name: 'search',
	// 	component: () => {
	// 		return import(/* webpackChunkName: "main" */ './views/Search.vue')
	// 	},
	// },
	{
		path: '/product/393',
		name: 'custom',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(/* webpackChunkName: "main" */ './views/Custom.vue')
		},
	},
	// {
	// 	path: '/product/edit/:order_product_id',
	// 	name: 'product-edit',
	// 	props: true,
	// 	component: () => {
	// 		return import(/* webpackChunkName: "main" */ './views/Product3.vue')
	// 	},
	// },
	{
		path: '/product/:product_id/:order_product_id?',
		name: 'product',
		props: true,
		beforeEnter: authGuard,
		//		props: (route) => ({ order_product_id: route.query.opid }),  
		component: () => {
			return import(/* webpackChunkName: "main" */ './views/Product3.vue')
		},
	},
	{
		path: '/edit3/:product_id',
		name: 'edit3',
		beforeEnter: authGuard,
		props: true,
		component: () => {
			return import(/* webpackChunkName: "staff" */ './views/edit3.vue')
		},
	}, {
		path: '/edit/:product_id',
		name: 'edit',
		beforeEnter: authGuard,
		props: true,
		component: () => {
			return import(/* webpackChunkName: "staff" */ './views/Edit2.vue')
		},
	},
	{
		path: '/edit/',
		name: 'edit-list',
		beforeEnter: authGuard,
		props: true,
		component: () => {
			return import(/* webpackChunkName: "staff" */ './views/EditList.vue')
		},
	},
	{
		path: '/payment/:result/:order_id',
		name: 'payment',
		beforeEnter: authGuard,
		props: true,
		component: () => {
			return import(/* webpackChunkName: "staff" */ './views/Payment.vue')
		},
	},
	{
		path: '/reports/payments',
		name: 'report-payments',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.payments.vue'
			)
		},
	}, {
		path: '/reports/coffee',
		name: 'report-coffee',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.Coffee.vue'
			)
		},
	},
	{
		path: '/reports/delivery',
		name: 'report-delivery',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.Delivery.vue'
			)
		},
	},
	{
		path: '/reports/departments',
		name: 'report-departments',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.Departments.vue'
			)
		},
	},
	{
		path: '/reports/sales',
		name: 'report-sales',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/sales1.vue'
			)
		},
	},
	{
		path: '/reports/sales2',
		name: 'report-sales2',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/sales.vue'
			)
		},
	},
	{
		path: '/reports/sales-monthly',
		name: 'report-sales-monthly',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.Sales.Monthly.vue'
			)
		},
	},
	{
		path: '/reports/orders',
		name: 'report-orders',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.Orders.vue'
			)
		},
	},
	{
		path: '/reports/tips',
		name: 'report-tips',
		props: true,
		beforeEnter: authGuard,
		component: () => {
			return import(
				/* webpackChunkName: "reports" */ './views/reports/Report.Tips.vue'
			)
		},
	},
	{
		path: '/:catchAll(.*)',
		component: () => {
			return import(/* webpackChunkName: "main" */ './views/NotFound.vue')
		},
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

// router.beforeEach(async (to, from, next) => {
// 	console.log('beforeEach')
// 	let table_number = sessionStorage.getItem('table_number')
// 	console.log(table_number)
// 	let is_staff = sessionStorage.getItem('is_staff')
// 	console.log(is_staff)
// 	if (is_staff) return next()
// 	if (table_number !== null) return next()
// 	return next('/')
// })

// import { useAuth0 } from '@auth0/auth0-vue';
// const { loginWithRedirect, isAuthenticated, isLoading, $watch } = useAuth0();

// router.beforeEach(async (to, from, next) => {
// 	console.log('beforeEach')

// 	// const authService = getInstance();
// 	// const guardAction = () => {
// 	// 	if (isAuthenticated) {
// 	// 		return next();
// 	// 	}

// 	// 	loginWithRedirect({ appState: { targetUrl: to.fullPath } });
// 	// };

// 	while(isLoading){}

// 	if (isAuthenticated) {
// 		return next();
// 	}

// 	loginWithRedirect({ appState: { targetUrl: to.fullPath } });

// 	// If the Auth0Plugin has loaded already, check the authentication state
// 	//if (!isLoading) {
// 	//	return guardAction();
// 	//}

// 	// $watch('isLoading', (isLoading) => {
// 	// 	if (isLoading === false) {
// 	// 		return guardAction();
// 	// 	}
// 	// });
// })

import { auth } from './store.js'
async function authGuard(to, from, next) {
	try{
	//console.log(to)
	let u = await auth.get_user()
	//console.log(u)
	if (!u)
		window.location.href = `/login?redirect_uri=${to.fullPath}`
	//	if (auth.is_staff || auth.customer_area)
	return next()
	//	return next('/table')

	//if (auth.customer_area !== null ) return next()
	}catch(e){console.log(e)}
}

// function checkAuth(to, from, next) {
// 	//console.log(JSON.stringify(router.app))
// 	console.log(auth)
// 	var is_staff = auth.is_staff
// 	return next();
// }

// router.beforeEach(async (to, from, next) => {
// 	let url = to.path
// 	let tracker_id = await track.get_tracker()
// 	console.log(`middleware : ${tracker_id} -> ${url}`)
// 	const r = await fetch('/100/login/track', {
// 		method: 'POST',
// 		headers: {
// 			Accept: 'application/json',
// 			'Content-Type': 'application/json',
// 		},
// 		body: JSON.stringify({ url: url, tracker_id: tracker_id }),
// 	})
// 	next()
// })

export default router
