import { createApp, reactive, watch } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'

import { products, orders, page, auth, config } from '@/store'
import { api2 } from './helpers'

import VueSweetalert2 from 'vue-sweetalert2'
import '@sweetalert2/theme-dark/dark.css'

import dayjs from 'dayjs'
import router from './router.js'

Number.prototype.toStringGbp = function () { return isNaN(this) ? this : (this / 100).toFixed(2); }
dayjs.prototype.toTimeString = function () {
	if (!this.isValid()) return '--'
	var today = dayjs().startOf('day')
	var week = dayjs().startOf('week')
	if (this.isAfter(today)) return this.format('HH:mm')
	else if (this.isAfter(week)) return this.format('ddd HH:mm')
	return this.format('ddd DD, HH:mm')
}

const audio = {
	play(type) {
		console.log(`playing ${type}`)
		var audio = new Audio(`/${type}.mp3`)

		const promise = audio.play()
		if (promise !== undefined) {
			promise
				.then(() => {
					// Autoplay started
				})
				.catch(error => { })
		}
	},
}

const socket = reactive({
	is_connected: false,
	ping_interval: 50,
	ping_timer: null,
	//	disconnect_timer: null,
	socket: null,
	_ws: null,
	track_id: null,
	token: null,
	init() {
		try {

			console.log(`track_id:${this.track_id}`)
			let url = serverURL + `?track_id=${this.track_id}&token=${this.token}`
			console.log(`WS: connecting to ${url}`)
			this._ws = new WebSocket(url)
			// console.log(`${new Date().toLocaleTimeString()} ws connected`)
			// this.is_connected = true

			if (this.ping_timer) clearTimeout(this.ping_timer)
			const i = this.ping_interval
			this.ping_timer = setInterval(() => {
				console.log('sending ping')
				if (this.is_connected) this._ws.send(JSON.stringify({ message: '__PING__' }))
				else this.init()
			}, i * 1000)

			this._ws.addEventListener('open', () => {
				this.is_connected = true
				console.log(`WS: ${new Date().toLocaleTimeString()} : connected`)
			})

			this._ws.addEventListener('close', () => {
				this.is_connected = false
				console.log(`WS: ${new Date().toLocaleTimeString()} : disconnected`)
				this.init()
			})

			this._ws.addEventListener('message', readIncomingMessage)
		} catch (e) {
			console.log(e)
		}
	},
})

const app = createApp(App)
app.use(VueSweetalert2)
require('dayjs/locale/en-gb')

dayjs.locale('en-gb')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/London')

app.config.globalProperties.$dayjs = dayjs


// try {
// 	var basket = new Order(JSON.parse(localStorage.getItem('basket'))) || new Order()
// } catch (e) {
// 	console.log(e)
// 	var basket = new Order()
// 	localStorage.setItem('basket', JSON.stringify(basket));
// }

// basket = reactive(basket)
// watch(basket, (n, o) => {
// 	localStorage.setItem('basket', JSON.stringify(n));
// })

var current = reactive({
	order: null,
	async add_product(product) {
		try {
			console.log('current.add_product')
			console.log(product)
			if (this.order.products.length == 0) {
				if (auth.is_staff)
					this.order.confirmation_time = dayjs()
				let r = await api2.put(`/101/orders?broadcast=false`, this.order)
				this.order.order_id = r.order_id
				app.config.globalProperties.$orders.items.push(this.order)
			}
			product.track_id = app.config.globalProperties.$auth.track_id
			product.order_id = this.order.order_id
			product.discount = 0
			product.comment = product.comment?product.comment.replace("'","`"):null
			product.final_price = product.total_price(false)
			product.take_away = this.order.take_away
			for (let [i, ingredient] of product.ingredients.entries())
				ingredient.position = i

			if (product.order_product_id) { 	// UPDATE			
				var r = await api2.put(`/101/orders/product`, product)
			} else {						// NEW
				var p = await api2.post(`/101/orders/product`, product)
				product.order_product_id = p.order_product_id
				this.order.products.push(product)
			}

			// var r = await api2.post(`/101/orders/product`, product)
			// product.order_product_id = r.order_product_id
			// this.order.products.push(product)
		} catch (e) {
			console.log(e)
		}
	},
})

app.config.globalProperties.$current = current
app.config.globalProperties.$orders = orders
app.config.globalProperties.$products = products
app.config.globalProperties.$page = page
app.config.globalProperties.$auth = auth
app.config.globalProperties.$api2 = api2
app.config.globalProperties.$socket = socket
app.config.globalProperties.$config = config
app.config.globalProperties.$log = {
	info: async function (source, message) { await this.save(source, message, 'info') },
	debug: async function (source, message) { await this.save(source, message, 'debug') },
	error: async function (source, message) { await this.save(source, message, 'error') },
	save: async function (source, message, level) {
		console.log(`${level}-${source}: ${message}`)
		await this.$api2.post(`/101/orders/product/deliver`, { track_id: auth.track_id, message: m, level: 'debug', source: source })
	},
}

//import { createAuth0 } from "@auth0/auth0-vue";

app.use(router)
// .use(
// 	createAuth0({
// 		domain: 'vpl.eu.auth0.com',
// 		clientId: 'eRSOk1F3YF6BPZ890JeIVjwPSSOIA5RY',
// 		authorizationParams: {
// 			redirect_uri: window.location.origin
// 		}
// 	})
// )


router.app = app

import VueGoogleCharts from 'vue-google-charts'
app.use(VueGoogleCharts)

app.mount('#app')

// WEBSOCKET =============================================================================

const serverURL =
	window.location.protocol == 'https:'
		? `wss://${window.location.hostname}:${window.location.port}/ws`
		: `ws://${window.location.hostname}:${window.location.port}/ws`

console.log(`websocket url : ${serverURL}`)

async function readIncomingMessage(event) {
	try {
		const o = JSON.parse(event.data)

		let message = o.message
		// if (o.track_id == auth.track_id) {
		// 	console.log('WS: message originated by this client. skipping')
		// 	return
		// }
		//console.log(o.action)

		if (message == '__CONNECTED__') return
		if (message == '__PONG__') return

		console.log(`WS : ${o}`)

		if (message == '__REFRESH__') {
			console.log('WS: Received refresh request from server')
			document.location.reload();
			return
		}

		if (message == '__LOGOUT__') {
			console.log('WS: Received logout request from server')
			document.location = '/logout';
			return
		}

		if (message == 'order.insert.from_table') {
			console.log('WS: order from customer')
			audio.play('new-order')
		}

		// if (o.object == 'order' && (auth.is_staff || o.track_id == auth.track_id)) {
		// 	// this is a security hole and needs fixing. every client receives all the orders then other customers orders are skipped on the client
		// 	// it needs to be filteres on the server side
		// 	let order = o.data
		// 	console.log(`WS: refresh order ${order.order_id}`)
		// 	orders.replace_order(order)

		// }

		await orders.update(true) // .then(x => console.log(orders.items))

		// var order_id = object.order_id
		// var i = orders.findIndex(x => x.order_id == order_id)
		// switch(type) {
		// 	case 'order':
		// 		if(i == -1)
		// 			orders.push(new Order(object))
		// 		else
		// 			orders[i] = new Order(object)
		// 	  	break;
		// 	case 'orders_product':
		// 		var j = orders[i].products.findIndex(x => x.id == object.id)
		// 		if(j == -1)
		// 			orders[i].products.push(new Product(object))
		// 		else
		// 			orders[i].products[j] = object
		// 	  	break;
		// 	case 'orders_payment':
		// 		var j = orders[i].payments.findIndex(x => x.payment_id == object.payment_id)
		// 		if(j == -1)
		// 			orders[i].payments.push(object)
		// 		else
		// 			orders[i].payments[j] = object
		// 	  	break;
		// 	default:
		//   }
	} catch (e) {
		console.log('---> readIncomingMessage')
		console.log(e)
	}
}

; (async () => {
	try {
		//page.load(true)
		let logged_in = await auth.init()
		//if (!logged_in) window.location.href = `/login?redirect_uri=${window.location.href}`
		//console.log(auth.track_id)
		//if (auth.is_staff) await config.init()
		await orders.update(auth.is_staff) // .then(x => console.log(orders.items))
		console.log('orders_updated')
		await products.init(page)
		console.log('products inited')

		console.log(`track_id: ${auth.track_id}`)
		socket.track_id = auth.track_id
		socket.token = auth.is_staff ? 'staff' : 'guest'
		page.is_ready = true
		socket.init()
		console.log('async-->')
	} catch (e) {
		console.log('---> async()')
		console.log(e)
	}finally{
		//page.load(false)
		//page.is_ready=true
	}
})()
